import React, { useContext, useEffect, useRef, useState } from "react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MessageList,
  Message,
  TypingIndicator,
  ChatContainer,
} from "@chatscope/chat-ui-kit-react";
import {
  Box,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Chip,
  Alert,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import { ChatContext } from "context/Chat";
import { useSpeechRecognition } from "react-speech-recognition";
import { useKijabeMessageParser } from "components/chatbot/kijabe/useKijabeMessageParser";
import { useKijabeMessageHandler } from "components/chatbot/kijabe/useKijabeMessageHandler";
import { useLocation } from "react-router-dom";
import { KijabeChatContext } from "context/KijabeChat";

// Import components
import KijabeOptions from "components/chatbot/Chat/KijabeOptions";
import Disclaimer from "components/chatbot/Chat/Disclaimer";
import ChatWrapper from "components/chatbot/Chat/ChatWrapper";
import KijabeMessageInput from "components/chatbot/Chat/KijabeMessageInput";
import ProfessionalDisclaimer from "../Chat/ProfessionalDisclaimer";
import AdminTestBar from "../AdminTestBar";

const KijabeChatbot = ({
  visible = true,
  branding,
  currentTab,
  setCurrentTab,
  expanded,
  openTab,
  tabs,
  boxRef,
}) => {
  const location = useLocation();
  const {
    messages = [],
    rateLimited,
    limit,
    isMessagesLoading,
    messageCount,
    setMessageCount,
    getRemainingMessages,
    newThread,
  } = useContext(KijabeChatContext);
  const chatBoxRef = useRef(null);

  const {
    transcript,
    finalTranscript,
    listening,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [inputValue, setInputValue] = useState("");
  const [computedValue, setComputedValue] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [submitInfo, setSubmitInfo] = useState(true);
  const [selectedAssistant, setSelectedAssistant] = useState("");
  const { parseMessage } = useKijabeMessageParser(openTab);
  const [showOptions, setShowOptions] = useState(true);

  const { handleSendRequest } = useKijabeMessageHandler({
    setIsTyping,
    setMessageCount,
    selectedAssistant,
  });

  // Auto-scroll effect
  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages, isTyping]);

  // Handle speech recognition
  useEffect(() => {
    if (transcript && listening) {
      setComputedValue(inputValue + " " + transcript);
    }

    if (finalTranscript) {
      setInputValue((prev) => prev + " " + finalTranscript);
      setComputedValue((prev) => prev + " " + finalTranscript);
    }
  }, [transcript, finalTranscript, listening, inputValue]);

  // Add this console log to debug
  useEffect(() => {
    if (selectedAssistant) {
      console.log("Selected assistant ID:", selectedAssistant);
    }
  }, [selectedAssistant]);

  const updateComputed = () => {
    setComputedValue(inputValue);
  };

  // Update the onSendMessage function to include language
  const onSendMessage = async (message) => {
    try {
      setInputValue("");
      await handleSendRequest(message);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  // Update the sendMessage function to include language
  const sendMessage = (message, assistantId = null) => {
    handleSendRequest(message, assistantId);
  };

  console.log("Current location:", location.pathname);

  const renderLimitMessage = () => {
    if (rateLimited) {
      return (
        <Box
          sx={{
            padding: "10px",
            backgroundColor: "#f8f9fa",
            borderRadius: "5px",
            margin: "10px 0",
            textAlign: "center",
          }}
        >
          <p>
            You've reached the maximum number of messages for this conversation.
          </p>
          <Button
            variant="contained"
            color="primary"
            onClick={newThread}
            sx={{ mt: 1 }}
          >
            Start New Chat
          </Button>
        </Box>
      );
    }

    if (getRemainingMessages() <= 2) {
      return (
        <Box
          sx={{
            padding: "5px",
            backgroundColor: "#fff3cd",
            borderRadius: "5px",
            margin: "5px 0",
            textAlign: "center",
            fontSize: "0.8rem",
          }}
        >
          <p>
            You have {getRemainingMessages()} messages remaining in this
            conversation.
          </p>
        </Box>
      );
    }

    return null;
  };

  if (isMessagesLoading) {
    return (
      <Box
        sx={{
          height: "100dvh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (true) {
    return (
      <Box
        sx={{
          height: "100dvh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Typography sx={{ textAlign: "center" }}>
          You must be logged in to use this feature.
        </Typography>
      </Box>
    );
  }

  if (!messages || messages.length === 0)
    return (
      <>
        <ProfessionalDisclaimer userData={branding} />
        <Box
          sx={{
            height: "100dvh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <KijabeOptions
            sendMessage={sendMessage}
            setSelectedAssistant={setSelectedAssistant}
          />
          <Disclaimer />
        </Box>
        {branding?.admin && (
          <AdminTestBar
            onAssistantChange={(value) => setSelectedAssistant(value)}
          />
        )}
      </>
    );

  return (
    <>
      <ProfessionalDisclaimer userData={branding} />
      <Box
        sx={{
          display: visible ? "block" : "none",
          width: "100%",
          overflow: "scroll",
        }}
        ref={chatBoxRef}
      >
        <Box>
          <ChatWrapper expanded={expanded} full={!tabs || tabs.length === 0}>
            <MessageList
              typingIndicator={
                isTyping ? (
                  <TypingIndicator content="HLTHDSK is typing..." />
                ) : null
              }
            >
              {messages?.map((message, i) => {
                const parsedText = parseMessage(message);

                return (
                  <Message
                    key={`message_${i}`}
                    model={{
                      direction: message.direction,
                      position: "normal",
                    }}
                  >
                    <Message.CustomContent>{parsedText}</Message.CustomContent>
                  </Message>
                );
              })}
            </MessageList>

            {renderLimitMessage()}

            <div
              as="MessageInput"
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                margin: "0 auto",
                paddingTop: 10,
                paddingBottom: 10,
                backgroundColor: "#fff",
              }}
            >
              <KijabeMessageInput
                inputValue={inputValue}
                setInputValue={setInputValue}
                computedValue={computedValue}
                handleSendRequest={onSendMessage}
                rateLimited={rateLimited}
                limit={limit}
                listening={listening}
                browserSupportsSpeechRecognition={
                  browserSupportsSpeechRecognition
                }
                updateComputed={updateComputed}
              />
            </div>
          </ChatWrapper>
        </Box>
      </Box>
    </>
  );
};

export default KijabeChatbot;
